<template>
  <Header/>
    <Banner name="About" message="About  Builders' Hub Vocational Academy" /> 
    <div class="section">
      <div class="section-padding-02 mt-n10">
          <div class="container">
              <div class="row">
                  <div class="col-lg-6">

                      <!-- About Images Start -->
                      <div class="about-images mb-4">
                          <div class="images">
                              <img src="@/assets/images/about.jpg" alt="About">
                          </div>

                          <div class="about-years">
                              <div class="years-icon">
                                  <img src="@/assets/images/logo-icon.png" alt="About">
                              </div>
                              <p><strong>{{ year }}+</strong> Years Experience</p>
                          </div>
                      </div>
                      <!-- About Images End -->
                          <p>
                            In the present context of globalization, the demand for skilled and multi - skilled workers has increased and in Nigeria, there is a critical need for quality skill development and training, specifically for the youth population. It has been projected that in next 10 years, over 100 million young people will enter into the Nigerian labour force. Against this background, there is need for capacity building/empowerment for our youth population for better employment status. The building and construction industry in Nigeria is currently challenged by poor workmanship which has led to the delivery of poor quality of works handled by Nigerian artisans and craftsmen.
                          </p>
                          <p>
                            <strong>Buildershub Group</strong> as a company has been working round the clock to proffering lasting solutions to all building needs hence the birth of Builders’ Hub Vocational Academy (BHVA). BHVA is poised to equip artisans and craftsmen with skills and knowledge for excellent job performance which will, in turn, accelerate the growth of the Building/Construction Industry in Nigeria and Africa at large.
                          </p>
                  </div>
                  <div class="col-lg-6">

                      <!-- About Content Start -->
                      <div class="about-content">
                          <h5 class="sub-title">Welcome to  Builders' Hub Vocational Academy</h5>
                          <h2 class="main-title">You can join  Builders' Hub Vocational Academy and upgrade your skill for your <span>bright future.</span></h2>

                          <p>
                            BHVA have also adopted the National Business and Technical Examination Board (NABTEB) skill standard in our training and also seeking to adopt NIOB/ CORBON National Occupational Standards in running the programs at BHVA. We have also collaborated with International Vocational Education and Training Association (IVETA) in delivery skill training in our academy. This is with a view to ensuring trainees have access to both the Local National Skills Qualification (NSQ) and the International National Vocational Qualification (NVQ) Certifications.
                          </p>
                          <p>
                            Builders’ Hub Vocational Academy (BHVA) is equipped with seasoned and qualified tutors, ready to impart their wealth of knowledge on the students, with practical and hands-on training sessions. We are aware that not everyone is seeking a qualification but we continue to ensure that even these programmes are taught by qualified instructors so you are assured that you are treated with the same dedication as those training for qualifications. If qualifications are your aim, BHVA has the relevant approvals in place to help you progress.
                          </p>
                          <p>
                            We know the construction industry well, as we consistently remain up to date with changes and practices to ensure the highest level of training is available at Builders’ Hub Vocational Academy.
                          </p>
                          <router-link :to="{name:'Courses'}" class="btn btn-primary btn-hover-dark">Start A Course</router-link>
                      </div>
                      <!-- About Content End -->

                  </div>
              </div>
          </div>
      </div>

      <div class="section-padding-02 mt-n6">
          <div class="container">

              <!-- About Items Wrapper Start -->
              <div class="about-items-wrapper">
                  <div class="row">
                      <div class="col-lg-4">
                          <!-- About Item Start -->
                          <div class="about-item">
                              <div class="item-icon-title">
                                  <div class="item-icon">
                                      <i class="flaticon-tutor"></i>
                                  </div>
                                  <div class="item-title">
                                      <h3 class="title">Top Instructors</h3>
                                  </div>
                              </div>
                              <p>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled to make type specimen book has survived.</p>
                          </div>
                          <!-- About Item End -->
                      </div>
                      <div class="col-lg-4">
                          <!-- About Item Start -->
                          <div class="about-item">
                              <div class="item-icon-title">
                                  <div class="item-icon">
                                      <i class="flaticon-coding"></i>
                                  </div>
                                  <div class="item-title">
                                      <h3 class="title">Portable Program</h3>
                                  </div>
                              </div>
                              <p>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled to make type specimen book has survived.</p>
                          </div>
                          <!-- About Item End -->
                      </div>
                      <div class="col-lg-4">
                          <!-- About Item Start -->
                          <div class="about-item">
                              <div class="item-icon-title">
                                  <div class="item-icon">
                                      <i class="flaticon-increase"></i>
                                  </div>
                                  <div class="item-title">
                                      <h3 class="title">Improve Quickly</h3>
                                  </div>
                              </div>
                              <p>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled to make type specimen book has survived.</p>
                          </div>
                          <!-- About Item End -->
                      </div>
                  </div>
              </div>
              <!-- About Items Wrapper End -->

          </div>
      </div>

  </div>
  <!-- About End -->
  <InstructorCTA/>
  <div class="section section-padding mt-n1">
    <div class="container">

        <!-- Section Title Start -->
        <div class="section-title shape-03 text-center">
            <h5 class="sub-title">Team Member’s</h5>
            <h2 class="main-title"> Builders' Hub Vocational Academy Skilled <span> Instructors</span></h2>
        </div>
        <!-- Section Title End -->

        <div class="team-wrapper">
          <div class="row row-cols-lg-5 row-cols-sm-3 row-cols-2 ">
              <div v-for="Instructor in Instructors" :key="Instructor" class="col">

                  <!-- Single Team Start -->
                  <div class="single-team">
                      <div class="team-thumb">
                          <img :src="Instructor.image" alt="Author">
                      </div>
                      <div class="team-content">
                          <div class="rating">
                              <span class="count">{{Instructor.rating}}</span>
                              <i class="icofont-star"></i>
                              <span class="text">(rating)</span>
                          </div>
                          <!-- <h4 class="name">{{ Instructor.name }}</h4> -->
                          <span class="designation">{{ Instructor.qualification }}, Instructor</span>
                      </div>
                  </div>
                  <!-- Single Team End -->

              </div>
          </div>
        </div>
    </div>
  </div>
  <CTA/>
  <Testimonal/>
    <Brand/>
  <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
import CTA from '@/components/CTA.vue';
import InstructorCTA from '@/components/home/InstructorsCTA.vue';
import Testimonal from '@/components/home/Testimonal.vue';
import Brand from '@/components/home/Brand.vue';
export default{
    name:"About",
    components:{
      Header,Footer,Banner,InstructorCTA,CTA,Testimonal,Brand
    },
    data(){
      return{
        year:"30",
        Instructors:[
          {name:"Mr Peter",rating:"4.0",qualification:"B TECH",image:"https://via.placeholder.com/400"},
          {name:"Mr Peter",rating:"4.0",qualification:"B TECH",image:"https://via.placeholder.com/400"},
          {name:"Mr Peter",rating:"4.0",qualification:"B TECH",image:"https://via.placeholder.com/400"},
          {name:"Mr Peter",rating:"4.0",qualification:"B TECH",image:"https://via.placeholder.com/400"},
          {name:"Mr Peter",rating:"4.0",qualification:"B TECH",image:"https://via.placeholder.com/400"}
        ]
      }
    }
}
</script>
