<template>
    <!-- Page Banner Start -->
    <div class="section page-banner">

     
        <img class="shape-2" src="@/assets/images/shape/shape-23.png" alt="Shape">

        <div class="container">
            <!-- Page Banner Start -->
            <div class="page-banner-content">
                <ul class="breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li class="active">{{ name }}</li>
                </ul>
                <h2 class="title">{{ mainWord }} <span>{{ lastWord }}</span></h2>
            </div>
            <!-- Page Banner End -->
        </div>

        <!-- Shape Icon Box Start -->
        <div class="shape-icon-box">

            <img class="icon-shape-1 animation-left" src="@/assets/images/shape/shape-5.png" alt="Shape">

            <div class="box-content">
                <div class="box-wrapper">
                    <i class="flaticon-badge"></i>
                </div>
            </div>

            <img class="icon-shape-2" src="@/assets/images/shape/shape-6.png" alt="Shape">

        </div>
        <!-- Shape Icon Box End -->

        <img class="shape-3" src="@/assets/images/shape/shape-24.png" alt="Shape">

        <!-- <img class="shape-author" src="@/assets/images/author/author-11.jpg" alt="Shape"> -->

    </div>
    <!-- Page Banner End -->
</template>
<script>
export default{
    name:"banner",
    props:['name','message'],
    computed:{
        lastWord(){
            let parts = this.message.split(" ");
            let lastWord = parts[parts.length - 1];
            return lastWord
        },
        mainWord(){
            let parts = this.message.split(" ");
            let lastWord = parts[parts.length - 1];
            let mainSentence =parts.filter((item) => item != lastWord);
            return mainSentence.join(" ")
        }
    }
}
</script>